<template>
	<div class="price-editing">
		<div class="breadcrumb-wrapper">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>价格编辑</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="main-search">
				<el-select v-model="query.type" placeholder="请选择查询范围">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
				<div class="search-input">
					<el-input v-model="query.keyword" clearable placeholder="请输入零件号或备品名称"></el-input>
					<el-button type="primary" v-loading="loading" @click="searchClick"><i class="el-icon-search"></i>
					</el-button>
				</div>
                <el-button type="primary" class="main-btn" v-loading="downloadLoading" @click="handleDownload">导出Excel</el-button>
			</div>
		</div>
		<el-table v-loading="loading" :data="tableData" class="main-table" header-row-class-name="table-header"
			cell-class-name="table-hover" :row-class-name="tableRowClassName" ref="table" highlight-current-row
			@row-dblclick="editClick">
			<el-table-column label="序号" type="index" width="80" align="center"></el-table-column>
            <el-table-column prop="askPriceUser.companyName" label="询价单位"></el-table-column>
            <el-table-column prop="askPriceUser.contact" label="联系人"></el-table-column>
            <el-table-column prop="askPriceUser.contactPhone" label="联系电话"></el-table-column>
            <el-table-column prop="askVin" label="VIN"></el-table-column>
			<el-table-column prop="name" label="备品名称"></el-table-column>
			<el-table-column prop="orderPartNo" label="原厂零件号"></el-table-column>
			<el-table-column prop="priceEndUser" label="指导价" align="center">
				<template slot-scope="scope">
					<el-input :placeholder="getPrice(scope.row) ? '原价: ' + getPrice(scope.row) : '请输入指导价'"
						v-model="scope.row.insurePrice.price" clearable oninput="value=value.replace(/[^\d.]/g,'')"
						:readonly="scope.row.readonly">
					</el-input>
				</template>
			</el-table-column>
			<el-table-column prop="priceEndUser" label="服务站价" align="center">
				<template slot-scope="scope">
					<el-input placeholder="请输入服务站价" v-model="scope.row.insurePrice.priceService"
						oninput="value=value.replace(/[^\d.]/g,'')" clearable :readonly="scope.row.readonly">
					</el-input>
				</template>
			</el-table-column>
			<el-table-column prop="priceEndUser" label="精品件价格" align="center">
				<template slot-scope="scope">
					<el-input placeholder="请输入精品件价格" v-model="scope.row.insurePrice.priceBrand"
						oninput="value=value.replace(/[^\d.]/g,'')" clearable :readonly="scope.row.readonly">
					</el-input>
				</template>
			</el-table-column>
			<el-table-column prop="priceEndUser" label="副厂件价格" align="center">
				<template slot-scope="scope">
					<el-input placeholder="请输入副厂件价格" v-model="scope.row.insurePrice.priceSubFactory"
						oninput="value=value.replace(/[^\d.]/g,'')" clearable :readonly="scope.row.readonly">
					</el-input>
				</template>
			</el-table-column>
			<el-table-column prop="priceEndUser" label="原厂供货价" align="center">
				<template slot-scope="scope">
					<el-input placeholder="请输入原厂供货价" v-model="scope.row.insurePrice.priceSupplier"
						oninput="value=value.replace(/[^\d.]/g,'')" clearable :readonly="scope.row.readonly">
					</el-input>
				</template>
			</el-table-column>
			<el-table-column label="操作" align="center" class-name="table-operate" width="150">
				<template slot-scope="scope">
					<el-button class="operation-btn" type="primary" size="small" @click="editClick(scope.row)"
						v-if="scope.row.readonly">编辑
					</el-button>
					<el-button type="warning" size="small" @click="saveClick(scope.row)" v-if="!scope.row.readonly">保存
					</el-button>
					<el-button type="danger" size="small" @click="delClick(scope.row)"
						v-if="isEdit(scope.row.insurePrice)">删除
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination-wrapper" v-if="tableData.length > 0">
			<el-pagination @size-change="handleSizeChange" @current-change="searchClick" :current-page.sync="query.page"
				:page-sizes="[10, 20, 30, 40]" :page-size.sync="query.pagesize"
				layout="total, sizes, prev, pager, next, jumper" :total="query.total">
			</el-pagination>
		</div>

	</div>
</template>

<script>
    import {
        fetchList,
        saveInsurePrice
    } from "../../api/insurePrice";
	import {
		getPrice
	} from "../../common/calcPrice";

	export default {
		data() {
			return {
                downloadLoading: false,
				loading: false,
				keyword: "",
				query: {
                    type: 1,
					keyword: '',
					page: 1,
					pagesize: 20,
					data: 'insurePrice',
					total: ''
				},
				tableData: [],
				options: [{
						value: 1,
						label: '全部'
					},
					{
						value: 2,
						label: '未编辑价格'
					},
                    {
                        value: 3,
                        label: '已编辑价格'
                    }
				]
			}
		},
        created() {
            this.searchClick()
        },
        methods: {
			getPrice(row) {
				return getPrice(row);
			},
			checkInput(e) {
				console.log(e)
				//only input number
				e = e.replace(/[^\d]/g, '');
			},
			isEdit(insurePrice) {
				if (insurePrice.price != '' || insurePrice.priceService != '' ||
					insurePrice.priceBrand != '' || insurePrice.priceSubFactory != '' ||
					insurePrice.priceSupplier != '') {
					return true;
				} else {
					return false;
				}
			},
			isAllEdit(insurePrice) {
				console.log(insurePrice.priceService)
				if (insurePrice.price.length > 0 && insurePrice.priceService.length > 0 &&
					insurePrice.priceBrand.length > 0 && insurePrice.priceSubFactory.length > 0 &&
					insurePrice.priceSupplier.length > 0) {
					return true;
				} else {
					return false;
				}
			},
			saveClick(row) {
				if (!this.isAllEdit(row.insurePrice)) {
					this.$message({
						message: '请填写完整所有价格',
						type: 'warning'
					});
					return false;
				}
				this.$confirm('确定要保存此次修改吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					saveInsurePrice(row.insurePrice).then(() => {
						this.$message({
							type: 'success',
							message: '保存成功!'
						});
						row.readonly = true;
					})
				}).catch(() => {});
			},
			delClick(row) {
				this.$confirm('确定要删除价格吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					row.insurePrice.price = '';
					row.insurePrice.priceService = '';
					row.insurePrice.priceBrand = '';
					row.insurePrice.priceSubFactory = '';
					row.insurePrice.priceSupplier = '';
					saveInsurePrice(row.insurePrice).then(() => {
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
						row.readonly = true;
						this.searchClick()
					})
				}).catch(() => {});
			},
			editClick(row) {
				this.$message({
					message: '此行信息价格可编辑！',
					type: 'success'
				});
				row.readonly = false
			},
			modifyDetails() {
				this.$router.push('/modifyDetails')
			},
			searchClick() {
				this.loading = true
				fetchList(this.query).then(res => {
					let list = res.data.list
					list.forEach(item => {
						if (!item.insurePrice) {
							item.insurePrice = {
								buyCode: item.orderPartNo,
								name: item.name,
								price: '',
								priceService: '',
								priceBrand: '',
								priceSubFactory: '',
								priceSupplier: ''
							}
						}
						item.readonly = true
					})
					this.tableData = list
					this.query.total = res.data.total
				}).finally(() => {
					this.loading = false
				})
			},
			handleSizeChange() {
				this.query.page = 1
				this.searchClick()
			},
			tableRowClassName({
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
            handleDownload() {
                this.downloadLoading = true
                let query = Object.assign({}, this.query)
                query.page = 1
                query.pagesize = 100000
                fetchList(query).then(res => {
                    let list = res.data.list;
                    import('@/vendor/Export2Excel').then(excel => {
                        const tHeader = ['单位名称', '联系人', '手机号', '备品名称', '原厂零件号'];
                        const filterVal = ['company', 'contact', 'phone', 'name', 'orderPartNo'];
                        const data = this.formatJson(filterVal, list)
                        excel.export_json_to_excel({
                            header: tHeader,
                            data,
                            filename: "价格列表",
                            autoWidth: false,
                            bookType: 'xlsx'
                        })
                        this.downloadLoading = false
                    })
                });
            },
            formatJson(filterVal, jsonData) {
                return jsonData.map(v => filterVal.map(j => {
                    if (j === 'contact') {
                        return v['askPriceUser'].contact
                    } else if (j === 'phone') {
                        return v['askPriceUser'].contactPhone
                    } else if (j === 'company') {
                        return v['askPriceUser'].companyName
                    } else {
                        return v[j]
                    }
                }))
            },
		}
	}
</script>

<style lang="scss" scoped>
	.breadcrumb-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.main-search {
			margin: 0;

			.search-input {
				margin: 0 0.1rem 0 0;
			}
		}
	}

	.el-select-dropdown__item {
		padding: 0 0.2rem;
	}
</style>
