import request from '@/utils/request'

export function saveInsurePrice(data) {
    return request({
        url: '/admin/insureprice/save',
        method: 'post',
        data
    })
}

export function fetchList(query) {
    return request({
        url: '/admin/insureprice/list',
        method: 'get',
        params: query
    })
}
